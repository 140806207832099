<template>
  <div id="products-container">
    <Head />
    <router-view />
  </div>
</template>

<script>
import Head from "@/components/head";
export default {
  data() {
    return {};
  },
  components: {
    Head,
  },
  computed: {},
  methods: {
  },
  created() {
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
#products-container {
}
</style>